/**
 * Created by jgolebiewski on 29.10.2015.
 */

App.factory('AuthService', ['$http', 'LOGGED_USER', 'webStorage', 'USER_ROLES', 'Session', 'localStorageService', 'gridService', function ($http, LOGGED_USER, webStorage, USER_ROLES, Session, localStorageService, gridService) {
    var authService = {};

    authService.isAuthenticated = function () {
        return  !! webStorage.local.get('sid');
    };

    authService.isAuthorized = function (authorizedRoles) {

        if (!angular.isArray(authorizedRoles)) {
            authorizedRoles = [authorizedRoles];
        }

        return (authService.isAuthenticated() && authorizedRoles.indexOf(LOGGED_USER.role.name) !== -1);
    };


    /**
     * Sprawdzanie wygasniecia hasla
     * @returns {boolean}
     */
    authService.checkPasswordExpiration = function () {
        var now = new Date(),
            d = now.getDate(),
            m = now.getMonth(),
            y = now.getFullYear(),
            updateDate = LOGGED_USER.passExpiration.split(" "),
            expirationDate = new Date(updateDate[0]),
            today = new Date(y, m, d);

        if (today.getTime() >= expirationDate.getTime()) {
            return true;
        }

        return false;
    };

    /**
     * sprawdzanie akceptacji regulaminow
     * @returns {boolean}
     */
    authService.disclaimers = function () {

        //nie sprawdzamy dla SU
        if (LOGGED_USER.role.name == USER_ROLES.su) {
            return  false;
        }

        //warunki trzeba dostosowac do tego wymagan
        var test = false;

        //testowy warunek
        //if (!LOGGED_USER.hasOwnProperty('disclaimer')) {
		if (LOGGED_USER.disclaimer==null) {
           test = true;
        }

        return test;
    };

    authService.checkFirstLogin = function () {

        if (LOGGED_USER.role.name != USER_ROLES.user) {
            return false
        }

        if (LOGGED_USER.firstLogin == null) {
            return  true;
        }

        return false;

    };

    authService.logout = function () {
	    $http.get('/logout').then(function (response) {
	        if (response.data.success) {
	            Session.destroy();

	            var listNames = ['DevicesList'];

	            if ($scope.loggedUser.role.name != $scope.userRoles.su) {
	            	gridService.getQueryOptionsFromStorage(listNames);
	            }

	            localStorageService.clearAll();

	            if ($scope.loggedUser.role.name != $scope.userRoles.su) {
	            	gridService.setQueryOptionsToStorage(listNames);
	            }

	            window.location.href = '/';
	        }

	        return false;
	    });
    };

    return authService;
}]);