auth.controller('LogoutController', ['$scope','$http','Session', 'localStorageService', 'gridService', function ($scope, $http, Session, localStorageService, gridService) {


    $http.get('/logout').then(function (response) {

        if (response.data.success == true) {
            Session.destroy();

            var listNames = ['DevicesList'];

            if ($scope.loggedUser.role.name != $scope.userRoles.su) {
            	gridService.getQueryOptionsFromStorage(listNames);
            }

            localStorageService.clearAll();

            if ($scope.loggedUser.role.name != $scope.userRoles.su) {
            	gridService.setQueryOptionsToStorage(listNames);
            }

            window.location.href = '/';
        }

        return false;
    });

}]);